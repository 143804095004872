import React, { useCallback, useEffect, useState } from "react";
import { GetWord } from "../../../../../utils";
import Service from "../../../../../Service";
import { Button, Form, Image, Input } from "antd";
import LoadingComp from "../../../../shared/loading/loading";
import { toast } from "react-toastify";

const ProfileDetail = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const service = new Service();
    service.VisaService.getProfile().then((res) => {
      setUser(res[0]);
      setLoading(false);
    });
  }, [user]);


  const onFinish = useCallback(async (value) => {



    // const service = new Service();
    // const data = {
    //   ...value,
    //   "istationImage": responseUrl ? responseUrl : station.image,
    // }

    // service.StationService.updateStation(data).then(() => {
    //   toast.success(GetWord("succes"))
    // }).catch((error) => {
    //   toast.error(error)
    // })
  }, [])

  if (loading)
    return <LoadingComp />

  return (
    <div className="row">
      <div className="col-9">
        <Form
          initialValues={user}
          onFinish={onFinish}
          name="validate_other"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item name="ProfilePicture" label={GetWord("ProfilePicture")}>
            <Image width={155} src={user?.image} />
          </Form.Item>

          <Form.Item
            name="name"
            label={GetWord("name")}
            rules={[
              {
                required: true,
                message: GetWord("nameRequired"),
              },
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item
            name="lastname"
            label={GetWord("lastName")}
            rules={[
              {
                required: true,
                message: GetWord("lastNameRequired"),
              },
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item
            name="email"
            label={GetWord("email")}
            readOnly
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: GetWord("email"),
              },
            ]}
          >
            <Input showCount maxLength={50} disabled={true} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={GetWord("phone")}
            rules={[
              {
                required: true,
                message: GetWord("phone"),
              },
            ]}
          >
            <Input showCount maxLength={14} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" block>
              {GetWord("updateSubmit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfileDetail;
