import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSideBtn } from "../../../../stores/site";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as TbIcons from "react-icons/tb";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";
import { getLocalstorage, GetWord } from "../../../../utils";
import { setlogout } from "../../../../stores/auth";
import { async } from "@firebase/util";
import { signoutUser } from "../../../../utils/firebase";
import { Decrypt } from "../../../../utils/encrypted";
import { Avatar, Badge, Button, Dropdown, Menu } from "antd";
import Service from "../../../../Service";
import NotificationItem from "./notification";

const Header = ({ pageName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [items, setNotification] = useState([]);

  const logoutHandler = async () => {
    await signoutUser();
    dispatch(setlogout());
    navigate("/auth");
  };

  const getNotif = () => {
    const service = new Service();
    service.GeneralService.getNotifications().then((res) => {
      const notf = res.map((item, index) => ({
        key: (index + 1).toString(),
        label: <NotificationItem item={item} />,
      }));
      setNotification([
        {
          key: "aq",
          label: (
            <Badge count={notf.length} className="icon">
              <IoIcons.IoIosNotificationsOutline />
            </Badge>
          ),
          children: notf,
        },
      ]);
    });
  };

  useEffect(() => {
    getNotif();
  }, []);

  return (
    <header>
      <div className="header-left ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "/Assets/images/logo.png"}
              alt=""
            />
          </div>
          <div className="text-white mt-auto mb-auto">
            <button
              className="togglebtn"
              onClick={() => dispatch(setSideBtn())}
            >
              <GiIcons.GiHamburgerMenu />
            </button>
          </div>
        </div>
      </div>

      <div className="header-center d-none d-md-inline">
        <p>Opet Akarca Petrol </p>
      </div>
      <div className="header-right">
        <button
          className="icon"
          onClick={() => {
            if (!document.fullscreenElement) {
              document.querySelector("#root").requestFullscreen();
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
            }
          }}
        >
          <BiIcons.BiFullscreen />
        </button>

        <Link to="/settings" className="icon">
          <MdIcons.MdOutlineSettings />
        </Link>

        {items ? (
          <Menu mode="horizontal" selectable={false} items={items}></Menu>
        ) : null}

        {/* <button className="icon">
          < />
        </button> */}

        <Link to={"/detail"}>
          <button className="icon">

            {JSON.parse(Decrypt(getLocalstorage("user"))).photoURL ? (
              <img
                src={JSON.parse(Decrypt(getLocalstorage("user"))).photoURL}
                alt="mdo"
                width="32"
                height="32"
                className="header-avatar-img"
              />
            ) : JSON.parse(Decrypt(getLocalstorage("user"))).displayName ? (
              <span className="header-avatar-span">
                {
                  JSON.parse(
                    Decrypt(getLocalstorage("user"))
                  ).displayName.split(" ")[0][0]
                }{" "}
                {JSON.parse(Decrypt(getLocalstorage("user"))).displayName.split(
                  " "
                ).length > 1
                  ? JSON.parse(
                    Decrypt(getLocalstorage("user"))
                  ).displayName.split(" ")[1][0]
                  : null}
              </span>
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/Assets/images/logo.png"}
                alt="mdo"
                width="32"
                height="32"
                className="header-avatar-img"
              />
            )}
          </button>
        </Link>
      </div>
    </header>
  );
};

export default memo(Header);
