import { useFormik } from "formik";
import React, { useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { GetWord } from "../../../utils";
import { setUserData, singUpUser } from "../../../utils/firebase";
import validations from "../../../utils/validations";

const Register = () => {
  const navigate = useNavigate();
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        name: "",
        phone: "",
        Reference: "",
        ChechReference: false,
      },
      onSubmit: async (values) => {
        const user = await singUpUser(values.email, values.password);
        if (user) {
          setUserData(
            user,
            values.name,
            values.Reference,
            values.ChechReference,
            values.email,
            values.phone
          );
        }
      },
      validationSchema: validations.registerValidations,
    });
  const [isVisable, SetIsVisable] = useState(false);

  const clickPass = () => {
    SetIsVisable((prev) => !prev);
  };

  return (
    <div className="row">
      <div className="col-12">
        <h3>
          <span className="text-primary">{GetWord("WelcomPetros")}</span>
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-input">
                <label htmlFor="name">{GetWord("nametxt")}</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder={GetWord("nametxt")}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.name && touched.name && (
                  <div className="form-input-text-error">
                    {GetWord(errors.name)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-input">
                <label htmlFor="email">{GetWord("email")}</label>
                <div className="d-flex">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={GetWord("email")}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </div>
                {errors.email && touched.email && (
                  <div className="form-input-text-error">
                    {GetWord(errors.email)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-input">
                <label htmlFor="phone">{GetWord("phone")}</label>
                <div className="d-flex">
                  <input
                    type="phone"
                    name="phone"
                    className="form-control"
                    placeholder={GetWord("phone")}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </div>
                {errors.phone && touched.phone && (
                  <div className="form-input-text-error">
                    {GetWord(errors.phone)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-input">
                <label htmlFor="password">{GetWord("password")}</label>
                <div className="form-input-group">
                  <input
                    type={isVisable ? "text" : "password"}
                    name="password"
                    className="form-control"
                    placeholder={GetWord("password")}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <a className="form-input-group-right" onClick={clickPass}>
                    {isVisable ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </a>
                </div>
                {errors.password && touched.password && (
                  <div className="form-input-text-error">
                    {GetWord(errors.password)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-input">
                <label htmlFor="Reference">{GetWord("Reference")}</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="Reference"
                    className="form-control"
                    placeholder={GetWord("Reference")}
                    value={values.Reference}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </div>
                {errors.Reference && touched.Reference && (
                  <div className="form-input-text-error">
                    {GetWord(errors.Reference)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mb-4 d-flex justify-content-between">
            <div>
              <div className="mb-4">
                <div className="form-check">
                  <input
                    type="checkbox"
                    name="ChechReference"
                    className="form-check-input"
                    value={values.ChechReference}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="ChechReference">
                    {GetWord("ChechReference")}
                  </label>
                  {errors.ChechReference && touched.ChechReference && (
                    <div className="form-input-text-error">
                      {GetWord(errors.ChechReference)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary btn-lg w-100">
              {GetWord("register")}
            </button>
          </div>
          <div className="col-12 pt-5 text-center">
            <Link to="/auth/" className="text-primary">
              <span> {GetWord("singin")}</span>
              <span className="text-bold">{GetWord("singinText")}</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
