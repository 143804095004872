import React, { useEffect, useState } from "react";
import Service from "../../../../Service";
import { GetWord } from "../../../../utils";
import LoadingComp from "../../../shared/loading/loading";
import PageMain from "../../components/PageMain/pagemain";
import CampaignsCardItem from "./components/campaignsCardItem";
import { Tabs } from "antd";
import CampaignsForm from "./components/campaignsForm";

const Campaigns = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(true);
  const [isUpdate, SetIsUpdate] = useState(true);
  const [activeTab, setActiveTab] = useState("2");

  useEffect(() => {
    const service = new Service();
    service.CampaignService.getStationCampaigns().then((res) => {
      setData(res);
      setLoading(false);
    });
  }, [isUpdate]);

  if (loading) {
    return <LoadingComp />;
  }

  const onchange = (id) => {
    setActiveTab(id);

  };

  const items = [
    {
      key: "1",
      label: GetWord("CampaignsAdd"),
      children: (
        <CampaignsForm
          setActiveTab={onchange}
          SetIsUpdate={SetIsUpdate}
          activeTab={activeTab}
        />
      ),
    },
    {
      key: "2",
      label: GetWord("MyCampaigns"),
      children: (
        <CampaignsCardItem
          setActiveTab={onchange}
          data={data}
          SetIsUpdate={SetIsUpdate}

        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <PageMain
        title={GetWord("evaluations")}
        descTitle={GetWord("CreateCampaign")}
      />
      <Tabs activeKey={activeTab} onChange={onchange} items={items} />
    </React.Fragment>
  );
};

export default Campaigns;
