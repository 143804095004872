import baseService from "./baseService";

export default class CampaignService extends baseService {
  constructor() {
    super();
    this.schemaName = "CampaignService";
  }

  getStationCampaigns() {
    return this.getObject("api", "getStationCampaigns");
  }
  getCampaigns() {
    return this.getObject("api", "getCampaigns");
  }
  campaigns() {
    return this.getObject("api", "campaigns");
  }
  deleteCampaign(data) {
    return this.deleteObject("api", "deleteCampaign", data);
  }
  addCampaign(entity) {
    return this.postObject("api", "addCampaign", entity);
  }
  updateCampaign(entity) {
    return this.patchObject("api", "updateCampaign", entity);
  }
}
