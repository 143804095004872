import { Alert, Modal } from 'antd'
import React, { useState } from 'react'
import { GetWord } from '../../../../utils'
import Service from '../../../../Service'
import { toast } from 'react-toastify'

const DeleteModal = ({ isModalOpen, setIsModalOpen, seletecedId, url, setIsReflesh, isreflesh }) => {
    const onDelete = () => {
        const service = new Service();
        service.GeneralService.deleteItem(url, seletecedId).then(() => {
            setIsReflesh((prev) => !prev);
            setIsModalOpen((prev) => !prev);
            toast.success(GetWord("succesDelete"));
        }).catch((err) => {
            setIsModalOpen((prev) => !prev);
            toast.err(GetWord("error"), GetWord("errorSystem"));
        });
    }
    return (
        <Modal open={isModalOpen} destroyOnClose footer={null} closable={false}>
            <div className='deleteModal'>
                <div className='deleteModal-message'>
                    {GetWord("DoYouWantToDelete")}
                </div>
                <div className='deleteModal-button'>
                    <button className='btn' onClick={() => setIsModalOpen((prev) => !prev)}>{GetWord("no")}</button>

                    <button className='btn btn-primary' onClick={() => onDelete()}>{GetWord("yes")}</button>
                </div>
            </div>
        </Modal >
    )
}

export default DeleteModal