import React from "react";
import { MdLocalGasStation } from "react-icons/md";
import { RiChargingPileFill } from "react-icons/ri";

const FuelCard = ({ item, index }) => {
  return (
    <div
      className={
        "FuelCard d-flex justify-content-between align-items-center FuelCard-" +
        (index + 1)
      }
    >
      <div className="FuelCard-header d-flex">
        <div className={"FuelCard-icon FuelCard-" + (index + 1) + "-icon"}>
          {item.fuelName == "Elektrik" ? (
            <RiChargingPileFill />
          ) : (
            <MdLocalGasStation />
          )}
        </div>
        <div className="FuelCard-text">
          <p className="FuelCard-text-name">{item.fuelName}</p>
          <p className="FuelCard-text-value">{item.fuelValue}</p>
        </div>
      </div>
      <div className={"FuelCard-price FuelCard-price-" + (index + 1)}>
        <p className="FuelCard-price-text">
          {item.price}{" "}
          <span className="FuelCard-price-currency">{item.currency}</span>
        </p>
      </div>
    </div>
  );
};

export default FuelCard;
