import baseService from "./baseService";

export default class StaffService extends baseService {
  constructor() {
    super();
    this.schemaName = "StaffService";
  }

  getMyWaitingStationApprovals() {
    return this.getObject("api", "getMyWaitingStationApprovals");
  }

  rejectStationOwner(data) {
    return this.patchUrl("api", "rejectStationOwner", data);
  }

  approveStationOwner(data) {
    return this.patchUrl("api", "approveStationOwner", data);
  }
}
