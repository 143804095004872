import { Avatar } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotificationItem = ({ item }) => {
  return (
    <Link
      className="d-flex justify-content-between align-item-center"
      to={"notification/" + item.notificationId}
    >
      <div>
        <Avatar>
          <img src={item.image} alt={item.title} />
        </Avatar>
      </div>
      <div>{item.title}</div>
    </Link>
  );
};

export default NotificationItem;
