import React from "react";
import { useLottie } from "lottie-react";
import loading from "./loading_animation.json";

const LoadingComp = () => {
  const options = {
    animationData: loading,
    loop: true,
    style: {
      height: 54,
      width: 125,
      borderRadius: 20,
      background: "#fff",
      padding: "41px 16px",
    },
  };
  const { View } = useLottie(options);
  return <div className="loading">{View}</div>;
};

export default LoadingComp;
