import { Button, DatePicker, Form, Input, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Service from "../../../../../Service";
import { GetWord } from "../../../../../utils";
import { ImgUpload } from "../../../../../utils/firebase";
import moment from "moment";
import dayjs from "dayjs";

const CampaignsForm = ({ SetIsUpdate, setActiveTab, selectedCamp, setModalStatus }) => {
  const [form] = Form.useForm();
  const [uploadImg, setUploadImg] = useState(null);
  const props = {
    multiple: false,
    beforeUpload: false,
  };

  useEffect(() => {
    form.resetFields()
  }, [form, selectedCamp])


  const onChange = async (info) => {
    if (info.file) {
      setUploadImg(info.file);
    }
  };

  const onFinish = useCallback(async (value) => {
    var responseUrl = await ImgUpload(
      uploadImg?.originFileObj,
      "campaignImages/",
      uploadImg?.name
    );
    const service = new Service();
    var requestData = {
      text: value.text,
      description: value.description,
      type: "station",
      startDate: value["StartDate"].format("YYYY-MM-DD HH:mm:ss"),
      endDate: value["EndDate"].format("YYYY-MM-DD HH:mm:ss"),
      navigationUrl: value.navigationUrl,
      image: uploadImg ? responseUrl : selectedCamp ? selectedCamp.image : null,
    };

    if (selectedCamp) {
      requestData["campaignId"] = selectedCamp.campaignId
      service.CampaignService.updateCampaign(requestData)
        .then((res) => {
          SetIsUpdate((prev) => !prev);
          toast.success(GetWord("succesUpdateCampaing"));
          form.resetFields();
          setModalStatus((prev) => !prev)
        })
        .catch((err) => {
          toast.error(GetWord("error"), err.message);
        });
    } else {

      service.CampaignService.addCampaign(requestData)
        .then((res) => {
          setActiveTab("2");
          SetIsUpdate((prev) => !prev);
          toast.success(GetWord("succesAddCampaing"));
          form.resetFields();
        })
        .catch((err) => {
          toast.error(GetWord("error"), err.message);
        });
    }



  }, [SetIsUpdate, form, selectedCamp, setActiveTab, uploadImg?.name, uploadImg?.originFileObj]);

  return (
    <div className="row">
      <div className="col-12">
        <h2>{selectedCamp ? GetWord("editcamps") : GetWord("CampaignsAdd")}</h2>
      </div>

      <div className="col-lg-12">
        <Form
          onFinish={onFinish}
          initialValues={selectedCamp}
          name="validate_other"
          labelCol={{ span: 4 }}
        >
          <Form.Item
            name="text"
            label={GetWord("CampaignName")}
            extra={GetWord("CampaignNameExtra")}
            rules={[
              {
                required: true,
                message: GetWord("CampaignNameRequired"),
              },
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>
          <Form.Item
            name="description"
            label={GetWord("FullDescription")}
            extra={GetWord("FullDescriptionExtra")}
            rules={[
              {
                required: true,
                message: GetWord("FullDescriptionRequired"),
              },
            ]}
          >
            <TextArea showCount maxLength={250} />
          </Form.Item>

          <Form.Item
            name="StartDate"
            label={GetWord("StartDate")}
            extra={GetWord("StartDateExtra")}
            rules={[
              {
                type: "object",
                required: true,
                message: GetWord("StartDateRequired"),
              },
            ]}
          >
            <DatePicker showTime defaultValue={dayjs(!selectedCamp ? new Date() : selectedCamp.startDate)} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="EndDate"
            label={GetWord("EndDate")}
            extra={GetWord("EndDateExtra")}
            rules={[
              {
                type: "object",
                required: true,
                message: GetWord("EndDateRequired"),
              },
            ]}
          >
            <DatePicker showTime defaultValue={dayjs(!selectedCamp ? new Date() : selectedCamp.endDate)} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="CampaigImg"
            label={GetWord("CampaigImg")}
            extra={GetWord("CampaigImgExtra")}
          //   getValueFromEvent={normFile}
          >
            <Upload.Dragger name="files" {...props} onChange={onChange}>
              <p className="ant-upload-drag-icon">{/* <InboxOutlined /> */}</p>
              <p className="ant-upload-text">
                {/* Click or drag file to this area to upload */}
              </p>
              <p className="ant-upload-hint">
                Yüklemek için PNG veya JPEG dosyalarını buraya bırakın
              </p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item
            name="navigationUrl"
            label={GetWord("CampaignLink")}
            extra={GetWord("CampaignLinkExtra")}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {GetWord("submit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CampaignsForm;
