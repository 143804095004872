import { Button, Form, Input, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Service from '../../../../../Service';
import { GetWord } from '../../../../../utils';
import { ImgUpload } from '../../../../../utils/firebase';
import LoadingComp from '../../../../shared/loading/loading';

const StationProfileDetail = () => {

  const [station, setStation] = useState();
  const [loading, setLoading] = useState(true);
  const [uploadImg, setUploadImg] = useState(null);
  const props = {
    multiple: false,
    beforeUpload: false,
  };

  const onChange = async (info) => {
    if (info.file) {
      setUploadImg(info.file);
    }
  };

  useEffect(() => {
    const service = new Service();
    service.StationService.getStationProfile().then((res) => {
      setStation(res[0]);
      setLoading(false);
    });
  }, []);

  const onFinish = useCallback(async (value) => {
    var responseUrl = null;
    if (uploadImg) {
      responseUrl = await ImgUpload(
        uploadImg.originFileObj,
        "stationImages/",
        uploadImg.name
      );

    }
    value.email = station.email;


    const service = new Service();
    const data = {
      ...value,
      "istationImage": responseUrl ? responseUrl : station.image,
    }

    service.StationService.updateStation(data).then(() => {
      toast.success(GetWord("succes"))
    }).catch((error) => {
      toast.error(error)
    })
  }, [station?.email, station?.image, uploadImg])

  if (loading)
    return <LoadingComp />

  return (
    <div className='row'>
      <div className="col-9">
        <Form
          initialValues={station}
          onFinish={onFinish}
          name="validate_other"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >

          <Form.Item
            name="istationName"
            label={GetWord("istationName")}
            rules={[
              {
                required: true,
                message: GetWord("istationNameRequired"),
              },
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item
            name="licenceNo"
            label={GetWord("licenceNo")}
            rules={[
              {
                required: true,
                message: GetWord("licenceNoRequired"),
              },
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item
            name="email"
            label={GetWord("email")}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: GetWord("emailRequired"),
              },
            ]}
          >
            <Input showCount maxLength={50} disabled={true} />
          </Form.Item>
          <Form.Item
            name="stationPhone"
            label={GetWord("stationPhone")}
            rules={[
              {
                required: true,
                message: GetWord("stationPhoneRequired"),
              },
            ]}
          >
            <Input showCount maxLength={14} />
          </Form.Item>
          <Form.Item
            name="address"
            label={GetWord("address")}
            rules={[
              {
                required: true,
                message: GetWord("addressRequired"),
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="StationImg"
            label={GetWord("StationImg")}
            extra={GetWord("StationImgExtra")}
          //   getValueFromEvent={normFile}
          >
            <Upload.Dragger name="files" {...props} onChange={onChange}>
              <p className="ant-upload-drag-icon">{/* <InboxOutlined /> */}</p>
              <p className="ant-upload-text">
                {/* Click or drag file to this area to upload */}
              </p>
              <p className="ant-upload-hint">
                Yüklemek için PNG veya JPEG dosyalarını buraya bırakın
              </p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" block>
              {GetWord("updateSubmit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default StationProfileDetail