import baseService from "./baseService";

export default class GeneralService extends baseService {
  constructor() {
    super();
    this.schemaName = "GeneralService";
  }

  packages() {
    return this.getObject("api", "packages");
  }

  getNotifications() {
    return this.getObject("api", "getNotifications");
  }
  addNotification(entity) {
    return this.postObject("api", "addNotification", entity);
  }
  getcustomers() {
    return this.getObject("api", "customers");
  }
  getFavouriteUsers() {
    return this.getObject("api", "getFavouriteUsers");
  }
  topTenCustomers() {
    return this.getObject("api", "topTenCustomers");
  }
  customersLoyalty() {
    return this.getObject("api", "customersLoyalty");
  }
  currencies() {
    return this.getObject("api", "currencies");
  }
  deleteItem(url, entity) {
    return this.deleteObject("api", url, entity);
  }
}
