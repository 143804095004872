import React, { useEffect, useState } from 'react'
import Service from '../../../../Service'
import LoadingComp from '../../../shared/loading/loading'
import PageMain from '../../components/PageMain/pagemain'
import { GetWord, getLocalstorage } from '../../../../utils'
import moment from 'moment'
import { MdLock } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Decrypt } from '../../../../utils/encrypted'
import { Button } from 'antd'
import CommentCard from './components/commentCard'

const Evaluations = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(true)
    const [update, setUpdate] = useState(true)
    const userType = getLocalstorage("stationProfile") == null ? "" : JSON.parse(Decrypt(getLocalstorage("stationProfile")));

    useEffect(() => {
        const service = new Service()
        service.StationService.getStationComments().then((res) => {
            setData(res)
            setLoading(false)
        })
    }, [update])

    if (loading)
        return <LoadingComp />
    return (
        <div className='Evaluations'>
            <PageMain title={GetWord("evaluations")} />
            {/* <div className='Evaluations-Header row bg-white p-3'>
                <div className="col-lg-4 text-center">1</div>
                <div className="col-lg-4 text-center">{GetWord("evaluations")}</div>
                <div className="col-lg-4 text-center">3</div>
            </div> */}
            <div className="Evaluations-body">
                {
                    data ? data.comments.map((item, index) => {
                        return (<CommentCard userType={userType} item={item} setUpdate={setUpdate} key={index} />)
                    }) : null
                }

            </div>
        </div>
    )
}

export default Evaluations