import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setlogout } from "../../../stores/auth";
import { signoutUser } from "../../../utils/firebase";

const Build = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = async () => {
    await signoutUser();
    dispatch(setlogout());
    navigate("/auth");
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <h1>YAPIM AŞAMASINDIR!!!</h1>
      <button onClick={logoutHandler}>ÇIKIŞ YAP</button>
    </div>
  );
};

export default Build;
