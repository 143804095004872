import React, { useEffect, useState } from 'react'
import Service from '../../../../../Service';
import PageMain from '../../../components/PageMain/pagemain'
import { Button, Table } from 'antd';
import { GetWord } from '../../../../../utils';
import moment from 'moment';
import { toast } from 'react-toastify';
import LoadingComp from '../../../../shared/loading/loading';
import StaffCard from './components/StaffCard';

const StaffHome = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [updateList, setUpdateList] = useState(true);

    useEffect(() => {
        setLoading(true);
        const service = new Service();
        service.StaffService.getMyWaitingStationApprovals().then((res) => {
            setData(res);
            setLoading(false)
        })
    }, [updateList])

    const onSave = (id) => {
        const service = new Service();
        service.StaffService.approveStationOwner("statusId=" + id).then((res) => {
            toast.success(GetWord("success"));
            setUpdateList((prev) => !prev)
        }).catch((error) => {
            toast.error(GetWord("error"));
        })
    }
    const onDelete = (id) => {
        const service = new Service();
        service.StaffService.rejectStationOwner("statusId=" + id).then((res) => {
            toast.success(GetWord("success"));
            setUpdateList((prev) => !prev)
        }).catch((error) => {
            toast.error(GetWord("error"));
        })
    }

    const columns = [
        {
            title: GetWord("date"),
            dataIndex: 'registrationDate',
            key: 'registrationDate',
            render: (text) => <span>{moment(text).format('l')}</span>,
        },
        {
            title: GetWord("name"),
            dataIndex: 'name',
            key: 'nema',
        },
        {
            title: GetWord("phone"),
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: GetWord("stationName"),
            dataIndex: 'title',
            key: 'title',
        }, {
            title: GetWord("status"),
            dataIndex: "status",
            key: "status",
            render: (text) => <span className={text}>{GetWord(text)}</span>,
        },
        {
            title: GetWord('Process'),
            key: 'action',
            render: (_, record) => (<div className='d-flex'>
                <Button disabled={record.status === "approved"} className='btn btn-primary me-2' onClick={() => onSave(record.statusId)}>{GetWord("save")}</Button>
                <Button disabled={record.status === "rejected"} className='btn btn-danger' onClick={() => onDelete(record.statusId)}>{GetWord("delete")}</Button>
            </div>
            )
        }
    ]
    if (loading) {
        return <LoadingComp />
    }

    return (
        <React.Fragment>
            <div className="staffHome">
                <PageMain />
                <div div className='row'>
                    {
                        data ? <StaffCard item={{ "status": "totalStation", "statusCount": data?.statusList.length, }} key={0} /> : null
                    }
                    {
                        data?.statusCounts.map((item, index) => {
                            return <StaffCard item={item} key={index} />
                        })
                    }
                </div>
                <div className="bg-white p-3 mt-3">
                    <Table columns={columns} dataSource={data?.statusList} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default StaffHome