import React from 'react'
import { GetWord } from '../../../../utils'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <div className='errorPage'>
            <h3>404</h3>
            <p>{GetWord("PageNoteFond")}</p>
            <Link to="/" className='btn'>{GetWord("GoToHome")}</Link>
        </div>
    )
}

export default ErrorPage