import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useRoutes } from "react-router-dom";
import AdminLayout from "../layout/admin/AdminLayout";
import Build from "../layout/admin/pages/build";
import Campaigns from "../layout/admin/pages/campaigns/campaigns";
import Detail from "../layout/admin/pages/detail/detail";
import Home from "../layout/admin/pages/home/stationHome/home";
import Sellertab from "../layout/admin/pages/sellertab/sellertab";
import { getLocalstorage, setLocalstorage } from "../utils";
import { Decrypt, Encrypt } from "../utils/encrypted";
import Notification from "../layout/admin/pages/notification/notification";
import CustomerLoyalty from "../layout/admin/pages/CustomerLoyalty /CustomerLoyalty";
import Settings from "../layout/admin/pages/settings/settings";
import Evaluations from "../layout/admin/pages/evaluations/evaluations";
import Adminhome from "../layout/admin/pages/home/adminHome/adminhome";
import StaffHome from "../layout/admin/pages/home/staffHome/StaffHome";
import Service from "../Service";
import LoadingComp from "../layout/shared/loading/loading";
import ErrorPage from "../layout/admin/pages/error/ErrorPage";

function RouteFunc({ data, dataType, setLoading }) {
  let userArray = [];
  if (data.userType === "station_owner") {
    userArray.push(
      { index: true, element: <Home /> },
      { path: "campaigns", element: <Campaigns /> },
      { path: "sellertab", element: <Sellertab /> },
      { path: "detail", element: <Detail /> },
      { path: "notification", element: <Notification /> },
      { path: "notification/:id", element: <Notification /> },
      { path: "settings", element: <Settings /> },
      { path: "evaluations", element: <Evaluations /> }
    );
    console.log(dataType);
    if (dataType)
      if (dataType.type === "enterprise") {
        userArray.push({
          path: "customerloyaltyindex",
          element: <CustomerLoyalty />,
        });
      }
    userArray.push(
      { path: "*", element: <Build /> },
      { path: "*", element: <ErrorPage /> }
    );
  }
  if (data.userType === "staff") {
    userArray.push(
      { index: true, element: <StaffHome /> },
      { path: "*", element: <ErrorPage /> }
    );
  }
  if (data.userType === "admin") {
    userArray.push(
      { index: true, element: <Adminhome /> },
      { path: "notification", element: <Notification /> },
      { path: "*", element: <ErrorPage /> }
    );
  }

  return useRoutes([
    {
      path: "/",
      element: <AdminLayout />,
      children: userArray,
    },
    { path: "team", element: "<AboutPage />" },
  ]);
}

const PrivateRouter = () => {
  const navigate = useNavigate();
  const user = getLocalstorage("user");
  const [userType, setUserType] = useState();
  const [type, setType] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate("/auth");
    } else {
      var userLoc = getLocalstorage("userType");
      var statinProfile = getLocalstorage("stationProfile");
      if (!userLoc) {
        const service = new Service();
        service.VisaService.getProfile().then((res) => {
          setUserType(res[0]);
          setLoading(false);
        });
        service.StationService.getStationProfile().then((res) => {
          setType(res[0]);
          setLoading(false);
        });
      } else {
        setUserType(JSON.parse(Decrypt(getLocalstorage("userType"))));
        setLoading(false);
      }
      if (statinProfile && userType?.userType === "station_owner") {
        setType(JSON.parse(Decrypt(statinProfile)));
      }
    }
  }, [user, navigate, userType?.userType]);

  return user ? (
    loading ? (
      <LoadingComp />
    ) : (
      <RouteFunc data={userType} dataType={type} setLoading={setLoading} />
    )
  ) : null;
};

export default PrivateRouter;
