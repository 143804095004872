import i18next from "i18next";
import * as yup from "yup";

let loginValidations = yup.object().shape({
  email: yup
    .string()
    .email("Lütfen geçerli bir mail adresi giriniz!")
    .required("mailRequired"),
  password: yup
    .string()
    .required("passwordRequired")
    .min(6, "Şifre en az 6 karakterden oluşmalıdır."),
});

let registerValidations = yup.object().shape({
  name: yup.string().required(i18next.t("nameRequired")),
  email: yup
    .string()
    .email("Lütfen geçerli bir mail adresi giriniz!")
    .required("mailRequired"),
  phone: yup
    .string()
    .required("phoneRequired")
    .min(10, "Telefon numarası en az 10 haneden oluşmalıdır"),
  password: yup
    .string()
    .required("passwordRequired")
    .min(6, "Şifre en az 6 karakterden oluşmalıdır."),
  Reference: yup.string(),
  ChechReference: yup.bool(),
});

export default { loginValidations, registerValidations };
