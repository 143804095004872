import React, { useEffect, useState } from 'react'
import PageMain from '../../components/PageMain/pagemain'
import { GetWord, labelsMounth } from '../../../../utils'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import Service from '../../../../Service';
import { Avatar, List, Skeleton } from 'antd';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CustomerLoyalty = () => {
    const [inComing, setInComing] = useState([]);
    const [outGoing, setOutGoing] = useState([]);
    const [topTen, setTopten] = useState();
    const [sales, setSales] = useState();

    useEffect(() => {
        const service = new Service();
        service.GeneralService.customersLoyalty().then((res) => {
            const arrayobject = Object.values(res)
            setInComing(arrayobject.map((value) => value.incoming));
            setOutGoing(arrayobject.map((value) => value.outgoing));
        })
        service.GeneralService.topTenCustomers().then((res) => {
            setTopten(res.topTen);
            setSales(res.sales);
        })
    }, [])

    const GlobalOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100
            }
        }
    };

    const labels = labelsMounth();
    const data = {
        labels,
        datasets: [
            {
                label: GetWord("MyStation"),
                data: inComing,
                backgroundColor: '#7700ff',
            },
            {
                label: GetWord("OtherStations"),
                data: outGoing,
                backgroundColor: '#39b0f9',
            },
        ],
    };


    const data2 = {
        responsive: true,
        labels: ['Elektrik', 'benzin', 'motorin', 'gaz'],
        datasets: [
            {
                label: 'LT',
                data: sales ? sales.map((item) => item.totalLiter) : [],
                backgroundColor: [
                    '#6f5ced',
                    '#d7745c',
                    '#6eb868',
                    '#e9c546',
                ],
                borderColor: [
                    '#fff',
                    '#fff',
                    '#fff',
                    '#fff',
                ],
                borderWidth: 1,
            },
        ],
    };


    return (
        <div className='CustomerLoyalty' >
            <PageMain title={GetWord("HelloWelcome")} />
            <div className='row'>
                <div className='col-xl-5'>
                    <div className=' p-3 bg-white'>
                        <Bar options={GlobalOptions} data={data} />
                    </div>
                </div>
                <div className='col-xl-3 text-center'>
                    <div className=' p-3 bg-white'>
                        <Doughnut data={data2} />
                        {/* <Bar options={GlobalOptions} data={data} /> */}
                    </div>
                </div>
                <div className='col-xl-4 '>
                    <div className=' p-3 bg-white'>
                        <List
                            itemLayout="horizontal"
                            dataSource={topTen}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.userImage} />}
                                            title={<span>{item.userName}</span>}
                                            description={GetWord("TotalLiter") + " " + item.totalLiter}
                                        />
                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                        {/* <Bar options={GlobalOptions} data={data} /> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CustomerLoyalty