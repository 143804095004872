import { initializeApp } from "firebase/app";
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-toastify";
import stores from "../stores";
import { setlogin, setlogout, setToken, setUserType } from "../stores/auth";
import { Decrypt, Encrypt } from "./encrypted";
import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { GetWord } from ".";

const firebaseConfig = {
  apiKey: "AIzaSyB1EtB9ivkvoxVUtoEhRmnGgC6MTq76ysM",
  authDomain: "petros-ff849.firebaseapp.com",
  databaseURL:
    "https://petros-ff849-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "petros-ff849",
  storageBucket: "petros-ff849.appspot.com",
  messagingSenderId: "1077608406379",
  appId: "1:1077608406379:web:a4941a1e0273dc6e9f002e",
  measurementId: "G-EQZVQS3WBY",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const login = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    console.log(error.code);
    toast.error(GetWord(error.code));
  }
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    user
      .getIdToken()
      .then((res) => {})
      .catch((err) => {
        stores.dispatch(setlogout());
      });
    stores.dispatch(setlogin(Encrypt(JSON.stringify(user))));
    stores.dispatch(setToken(Encrypt(JSON.stringify(user.accessToken))));
    stores.dispatch(setUserType());
  } else {
    stores.dispatch(setlogout());
  }
});

export const RefleshToken = () => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      user
        .getIdToken()
        .then((res) => {})
        .catch((err) => {
          stores.dispatch(setlogout());
        });
      stores.dispatch(setlogin(Encrypt(JSON.stringify(user))));
      stores.dispatch(setToken(Encrypt(JSON.stringify(user.accessToken))));
    } else {
      stores.dispatch(setlogout());
    }
  });
};

export const signoutUser = async () => {
  try {
    return await signOut(auth);
  } catch (error) {
    toast.error(error.code);
  }
};

export const userResetPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      return true;
    })
    .catch((error) => {
      toast.error(error.code);
    });
};

export const singUpUser = async (email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return user;
  } catch (error) {
    toast.error(error.code);
  }
};

export const setUserData = async (
  user,
  displayName,
  referenceCode,
  hasReferenceCode,
  email,
  phone
) => {
  await setDoc(doc(db, "users", user.uid), {
    uid: user.id,
    displayName,
    email,
    referenceCode,
    hasReferenceCode,
    phone,
  });
};

export const ImgUpload = async (file, folderName, filename) => {
  const imageRef = ref(storage, folderName + filename);
  try {
    await uploadBytes(imageRef, file);
    const responseFileUrl = await getDownloadURL(imageRef);
    return responseFileUrl;
  } catch (error) {
    toast.error(GetWord("error"), error.message);
  }
};

export default app;
