import { Tabs } from "antd";
import React, { useState } from "react";
import { GetWord } from "../../../../utils";
import Loading from "../../../shared/loading/loading";
import PageMain from "../../components/PageMain/pagemain";
import ProfileDetail from "./components/profileDetail";
import StationProfileDetail from "./components/stationProfileDetail";

const Detail = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(true);
  const [activeTab, setActiveTab] = useState("1");

  if (loading) {
    return <Loading />;
  }

  const onchange = (id) => {
    setActiveTab(id);
  };

  const items = [
    {
      key: "1",
      label: GetWord("ProfileInfo"),
      children: <ProfileDetail> </ProfileDetail>,
    },
    {
      key: "2",
      label: GetWord("StationInfo"),
      children: <StationProfileDetail> </StationProfileDetail>,
    },
  ];
  return (
    <div>
      <PageMain title={GetWord("Details")} descTitle={GetWord("ProfileInfo")} />
      <div className="detail">
        <Tabs activeKey={activeTab} onChange={onchange} items={items} />
      </div>
    </div>
  );
};

export default Detail;
