import React, { memo } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { GetWord } from "../../../../utils";

const SidebarItem = ({ isHeader, title, icon, pathname }) => {
  return (
    <React.Fragment>
      {isHeader ? (
        <div className="nav-header">{GetWord(title)}</div>
      ) : (
        <NavLink className="nav-link" to={pathname}>
          {icon}
          <span className="nav-title">{GetWord(title)}</span>
          <AiOutlineRight className="nav-drop" />
        </NavLink>
      )}
    </React.Fragment>
  );
};

export default memo(SidebarItem);
