import React, { useEffect, useState } from 'react'
import { MdLock } from 'react-icons/md'
import { GetWord } from '../../../../../utils'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import moment from 'moment'
import TextArea from 'antd/es/input/TextArea'
import { toast } from 'react-toastify'
import Service from '../../../../../Service'

const CommentCard = ({ item, userType, setUpdate }) => {
    const [data, setData] = useState(item.answerComment);

    const onclick = (commentId, answerComment) => {
        if (data) {
            const service = new Service()
            if (answerComment) {
                service.StationService.updateStationComment({
                    "comment": data,
                    "commentId": commentId
                }).then((res) => {
                    toast.success(GetWord("succes"));
                    setUpdate((prev) => !prev)
                }).catch((err) => {
                    toast.error(GetWord("error"));
                })
            } else {
                service.StationService.postStationComment({
                    "comment": data,
                    "commentId": commentId
                }).then((res) => {
                    toast.success(GetWord("succes"));
                    setUpdate((prev) => !prev)
                }).catch((err) => {
                    toast.error(GetWord("error"));
                })
            }

        } else {
            toast.error("boş Geçilemez..");
        }

    }
    const onChange = (e) => {
        setData(e.target.value)
    };


    return (
        <div className='Evaluations-body-comment d-flex' key={item.commentId}>
            <div className='avatar'><img src={item.image} alt={item.name} /></div>
            <div className='ms-3'>
                <p className='Evaluations-body-comment-title'>{item.name}</p>
                <p className='Evaluations-body-comment-date'>{moment(item.creationDate).format("LLL")}</p>
                <p className='Evaluations-body-comment-text'>{item.comment}</p>

                {
                    userType.type === "enterprise" ? <TextArea rows={4} onChange={onChange} defaultValue={item.answerComment} value={data} /> : <div className='Evaluations-body-comment-blocked'>
                        <MdLock className='icon' />
                        <p>{GetWord("commentblocked")}</p>
                        <Link to="/sellertab" className="btn btn-primary btn-small">{GetWord("PriceTable")}</Link>
                    </div>
                }

                <div className='mt-3 d-flex justify-content-end align-items-center'>
                    <Button className='btn btn-primary' disabled={!(userType.type === "enterprise")} size="large" onClick={() => onclick(item.commentId, item.answerComment)}>Gönder</Button>
                </div>
            </div>
        </div>
    )
}

export default CommentCard