import React, { useEffect, useState } from "react";
import PageMain from "../../components/PageMain/pagemain";
import { Select, Tabs } from "antd";
import { GetWord } from "../../../../utils";
import LoadingComp from "../../../shared/loading/loading";
import Service from "../../../../Service";
import FuelupdatePrice from "./components/fuelupdatePrice";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("2");
  const { t, i18n } = useTranslation();

  const onchange = (id) => {
    setActiveTab(id);

  };
  const handleChange = (value) => {
    i18n.changeLanguage(value);
  };

  const items = [
    {
      key: "1",
      label: GetWord("settings"),
      children: (
        <div className="row">
          <div className="col-12">
            <Select
              defaultValue={i18n.language}
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 'tr',
                  label: '🇹🇷 Türkçe',
                },
                {
                  value: 'en',
                  label: '🇬🇧 English',
                },

              ]}
            />
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: GetWord("FuelPrices"),
      children: (
        <FuelupdatePrice />
      ),
    },
  ];
  return (
    <React.Fragment>
      <PageMain
        title={GetWord("settings")}
        descTitle={GetWord("settings")}
      />
      <Tabs activeKey={activeTab} onChange={onchange} items={items} />
    </React.Fragment>);
};

export default Settings;
