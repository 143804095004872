import CampaignService from "./CampaignService";
import StationService from "./StationService";
import GeneralService from "./GeneralService";
import VisaService from "./VisaService";
import StaffService from "./StaffService";

export default class Service {
  VisaService = new VisaService();
  CampaignService = new CampaignService();
  StationService = new StationService();
  GeneralService = new GeneralService();
  StaffService = new StaffService();
}
