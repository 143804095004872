import React, { useEffect, useState } from "react";
import { MdOutlineLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setlogout } from "../../../../stores/auth";
import { GetWord } from "../../../../utils";
import { signoutUser } from "../../../../utils/firebase";
import Service from "../../../../Service";

const PageMain = ({ title, descTitle, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = async () => {
    await signoutUser();
    dispatch(setlogout());
    navigate("/auth");
  };
  return (
    <div className="d-flex justify-content-between pageMain">
      <div className="">
        <h3>{title}</h3>
        <h4>{descTitle}</h4>
      </div>
      <div className="">
        <button
          className="btn btn-danger btn-icon btn-login"
          onClick={logoutHandler}
        >
          {GetWord("logoutbtn")} <MdOutlineLogout />
        </button>
        {children}
      </div>
    </div>
  );
};

export default PageMain;
