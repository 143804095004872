import { Button, Dropdown, Modal, Popover } from "antd";
import React, { memo, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GetWord } from "../../../../../utils";
import Dorpdown from "../../../../shared/dropDown/Dorpdown";
import { IoMdTrash } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import Service from "../../../../../Service";
import { toast } from "react-toastify";
import CampaignsForm from "./campaignsForm";
import { Link } from "react-router-dom";
import DeleteModal from "../../../components/deleteModal/deleteModal";

const CampaignsCardItem = ({
  data,
  SetIsUpdate,
}) => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selected, isSelected] = useState(null);
  const [selectedId, isSelectedId] = useState(null);

  const btnDelete = (id) => {
    isSelectedId(id);
    setIsDeleteModalOpen(true);
    // const service = new Service();
    // service.CampaignService.deleteCampaign("campaignId=" + id)
    //   .then((res) => {
    //     SetIsUpdate((prev) => !prev);
    //     toast.success(GetWord("succes"), GetWord("succes"));
    //   })
    //   .then((err) => {
    //     toast.err(GetWord("error"), err.message);
    //   });
  };
  const btnEdit = (item) => {
    isSelected(item)
    setIsModalOpen(true)
    // setActiveTab("1");
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <div className="row">
      <div className="col-12">
        <h2>{GetWord("MyCampaigns")}</h2>
      </div>
      {data
        ? data.map((item, index) => {
          const items = [
            {
              label: (
                <a onClick={() => btnEdit(item)}>
                  <MdModeEditOutline /> {GetWord("Edit")}
                </a>
              ),
              key: "0",
            },
            {
              label: (
                <a onClick={() => btnDelete(item.campaignId)}>
                  <IoMdTrash /> {GetWord("Delete")}
                </a>
              ),
              key: "1",
            },
          ];
          return (
            <div className="col-lg-6 mb-5" key={index}>
              <div className="CampaignCard">
                <div className="CampaignCard-header">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      className="btn-ligth"
                      onClick={handleOpenChange}
                      icon={<BsThreeDotsVertical />}
                    />
                  </Dropdown>
                </div>
                <a href={item.navigationUrl} target="_blank">
                  <div className="CampaignCard-footer">
                    <p>{item.text}</p>
                  </div>
                  <div className="CampaignCard-body">
                    <img src={item.image} alt="is" />
                  </div>
                </a>
              </div>
            </div>
          );
        })
        : null}
      <DeleteModal setIsReflesh={SetIsUpdate} seletecedId={"campaignId=" + selectedId} url="deleteCampaign" setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} />

      <Modal open={isModalOpen} destroyOnClose footer={null} onCancel={() => setIsModalOpen(false)} width={750} style={{ top: 20 }}>
        <CampaignsForm selectedCamp={selected} setModalStatus={setIsModalOpen} SetIsUpdate={SetIsUpdate} />
      </Modal>
    </div>
  );
};

export default CampaignsCardItem;
