import { Form, Input } from 'antd'
import React from 'react'
import { GetWord } from '../../../../../utils'
import TextArea from 'antd/es/input/TextArea'

const SendNotification = ({ userType }) => {
    return (
        <div>
            <Form
                // onFinish={onFinish}
                name="validate_other"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    name="title"
                    label={GetWord("NotificationTitle")}
                    extra={GetWord("NotificationTitleExtra")}
                    rules={[
                        {
                            required: true,
                            message: GetWord("NotificationTitleRequired"),
                        },
                    ]}
                >
                    <Input showCount maxLength={50} />
                </Form.Item>
                <Form.Item
                    name="body"
                    label={GetWord("NotificationBody")}
                    extra={GetWord("NotificationBodyExtra")}
                    rules={[
                        {
                            required: true,
                            message: GetWord("NotificationBodyRequired"),
                        },
                    ]}
                >
                    <TextArea showCount maxLength={250} />
                </Form.Item>
            </Form>
        </div>
    )
}

export default SendNotification