import { InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdLocalGasStation } from 'react-icons/md'
import { RiChargingPileFill } from 'react-icons/ri'
import { GetWord } from '../../../../../utils'
import Service from '../../../../../Service'
import { toast } from 'react-toastify'

const FuelupdatePriceCard = ({ item, index, SetIsUpdate, setLoading }) => {
    const [inputValue, setValue] = useState(item.price);

    useEffect(() => {
        setValue(item.price);
    }, [item.price])

    const onclickbtn = (priceId) => {
        setLoading(true);
        const service = new Service();
        const requestData = {
            "fuelPriceId": priceId,
            "price": inputValue
        }
        service.StationService.updateFuelPrice(requestData).then((res) => {
            setLoading(false);
            toast.success(GetWord("success"));
            SetIsUpdate((prev) => !prev);
            setValue(null)
        }).catch((err) => {
            toast.error(err);
            setLoading(false);
        })

    }

    return (
        <div className='col-xl-4 col-lg-6  mb-4 ' key={index}>
            <div className='settings-price-card d-flex justify-content-between align-items-center'>
                <div className='d-flex'>
                    <div className={'settings-price-card-icon settings-price-card-icon-' + (index + 1)}>
                        {item.fuelName == "Elektrik" ? (
                            <RiChargingPileFill />
                        ) : (
                            <MdLocalGasStation />
                        )}
                    </div>
                    <div className="settings-price-card-text">
                        <p className="settings-price-card-text-name">{item.fuelName}</p>
                        <p className="settings-price-card-text-value">{item.fuelValue}</p>
                    </div>
                </div>
                <div className={"settings-price-card-price"}>
                    <p className="settings-price-card-price-text">
                        {item.price}{" "}
                        <span className="settings-price-card-price-currency">{item.currency}</span>
                    </p>
                </div>

            </div>
            <div className='settings-price-card'>
                <InputNumber min={0} className='w-100' size="large" value={inputValue} placeholder={GetWord("EnterTheNewPriceValue")} onChange={(value) => setValue(value)} />
                <button className='btn btn-primary w-100 mt-3' onClick={() => onclickbtn(item.fuelPriceId)}>{GetWord("ChangePrice")}</button>
            </div>
        </div>
    )
}

export default FuelupdatePriceCard