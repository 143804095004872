import React, { memo, useEffect, useMemo, useState } from 'react'
import Service from '../../../../../Service';
import { Table } from 'antd';
import { GetWord } from '../../../../../utils';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const PastNotification = ({ userType }) => {
    const [notification, setNotification] = useState([]);
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);

    const getNotif = () => {
        const service = new Service();
        service.GeneralService.getNotifications().then((res) => {
            setNotification(res)
        });
    }
    useEffect(() => {
        getNotif();
    }, []);

    const adminComp = useMemo(() => {
        const columns = [
            {
                title: GetWord('Date'),
                dataIndex: 'date',
                key: 'date',
                render: (text) => <span>{moment(text).format('l')}</span>,
            },
            {
                title: GetWord('Title'),
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: GetWord('Body'),
                dataIndex: 'body',
                key: 'body',
            },
            {
                title: GetWord('Type'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: GetWord('Process'),
                key: 'action',
                render: (_, record) => (
                    <button className='btn btn-primary' onClick={() => console.log(record.notificationId)}>{GetWord("SendAgain")}</button>
                )
            }



        ]

        return (<Table columns={columns} dataSource={notification} />)
    }, [notification])

    return (
        <React.Fragment>
            {
                userType === "admin" ? <div className='notification-admin'>
                    {adminComp}
                </div> : <div className='notification-user'>kullanici</div>
            }
        </React.Fragment>
    )
}

export default memo(PastNotification);