import axios from "axios";
import { getLocalstorage } from "../utils";
import { Decrypt } from "../utils/encrypted";
import { RefleshToken } from "../utils/firebase";

const globalApiURL = "https://api.mobsmile.com/petros/dev/";

export default class baseService {
  postObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "POST");
  }

  getObject(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, data, "GET");
  }

  putObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PUT");
  }

  deleteObject(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, null, "DELETE");
  }

  patchObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PATCH");
  }
  patchUrl(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, null, "PATCH");
  }

  callServiceMethod(url, data, method, cancelToken) {
    const token = Decrypt(getLocalstorage("tkn"));

    var headers = {
      Authorization: token ? "Bearer " + JSON.parse(token) : "",
    };

    return this.callAnyServiceMethod(url, data, method, headers, cancelToken);
  }

  async callAnyServiceMethod(url, data, method, headers, cancelToken) {
    var authOptions = {
      method,
      url,
      data,
      headers,
      withCredentials: false,
      cancelToken,
    };

    return await axios(authOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.request.status == 401) {
          RefleshToken();
        }
      });
  }
}
