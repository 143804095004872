import baseService from "./baseService";

export default class StationService extends baseService {
  constructor() {
    super();
    this.schemaName = "StationService";
  }

  getStationFuelPrices() {
    return this.getObject("api", "getStationFuelPrices");
  }
  getStationProfile() {
    return this.getObject("api", "getStationProfile");
  }
  updateStation(entity) {
    return this.patchObject("api", "updateStation", entity);
  }
  getExpensesForStation(pageSize, PageNumber) {
    return this.getObject(
      "api",
      "getExpensesForStation",
      "page=" + PageNumber + "&pageSize=" + pageSize
    );
  }
  getStationFeedbacks() {
    return this.getObject("api", "getStationFeedbacks");
  }
  updateFuelPrice(entity) {
    return this.patchObject("api", "updateFuelPrice", entity);
  }
  getStationComments() {
    return this.getObject("api", "getStationComments");
  }
  postStationComment(entity) {
    return this.postObject("api", "postStationComment", entity);
  }
  updateStationComment(entity) {
    return this.patchObject("api", "updateStationComment", entity);
  }
}
