import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AuthLayout from "../layout/auth/AuthLayout";
import Login from "../layout/auth/pages/login";
import PasswordReset from "../layout/auth/pages/PasswordReset";
import Register from "../layout/auth/pages/register";
import { getLocalstorage } from "../utils";
import Service from "../Service";

const AuthRouter = () => {
  const user = getLocalstorage("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  return !user ? (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route index={true} element={<Login />} />
        <Route path="resetpassword" element={<PasswordReset />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  ) : null;
};

export default AuthRouter;
