import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SidebarItem from "./SideBarItem";
import * as biIcon from "react-icons/bi";
import * as giIcon from "react-icons/gi";
import * as bsIcon from "react-icons/bs";
import * as aiIcon from "react-icons/ai";
import * as riIcon from "react-icons/ri";
import { TbGasStation } from "react-icons/tb";
import { Decrypt } from "../../../../utils/encrypted";
import { getLocalstorage } from "../../../../utils";
import Service from "../../../../Service";
import LoadingComp from "../../../shared/loading/loading";
import { IoIosNotificationsOutline } from "react-icons/io";
import { TbBox } from "react-icons/tb"
import { MdLocalGasStation } from "react-icons/md";

const datas = [
  {
    id: 1,
    isHeader: true,
    title: "Public",
  },
  {
    id: 2,
    isHeader: false,
    title: "Home",
    icon: <biIcon.BiHomeCircle className="nav-icon" />,
    pathname: "/",
  },
  {
    id: 3,
    isHeader: true,
    title: "Workers",
  },
  {
    id: 4,
    isHeader: false,
    title: "PriceTable",
    icon: <aiIcon.AiOutlineAppstore className="nav-icon" />,
    pathname: "/sellertab",
  },
  {
    id: 5,
    isHeader: false,
    title: "campaigns",
    icon: <bsIcon.BsArchive className="nav-icon" />,
    pathname: "/campaigns",
  },
  {
    id: 6,
    isHeader: false,
    title: "evaluations",
    icon: <bsIcon.BsFileBarGraph className="nav-icon" />,
    pathname: "/evaluations",
  },
  // {
  //   id: 7,
  //   isHeader: false,
  //   title: "Grafikler",
  //   icon: <riIcon.RiPieChartLine className="nav-icon" />,
  //   pathname: "/graphics",
  // },
  {
    id: 8,
    isHeader: false,
    title: "detail",
    icon: <giIcon.GiHamburgerMenu className="nav-icon" />,
    pathname: "/detail",
  },
  // {
  //   id: 9,
  //   isHeader: false,
  //   title: "Konum",
  //   icon: <biIcon.BiMapAlt className="nav-icon" />,
  //   pathname: "/location",
  // },
  {
    id: 10,
    isHeader: true,
    title: "Extras",
  },
  {
    id: 11,
    isHeader: false,
    title: "settings",
    icon: <riIcon.RiSettings2Line className="nav-icon" />,
    pathname: "/settings",
  },
  // {
  //   id: 12,
  //   isHeader: false,
  //   title: "customerloyaltyindex",
  //   icon: <riIcon.RiPieChartLine className="nav-icon" />,
  //   pathname: "/customerloyaltyindex",
  // },
];


const Sidebar = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadingMenu()
  }, [])

  const loadingMenu = () => {
    const service = new Service();
    service.VisaService.getProfile().then((res) => {
      setLoading(false);
      if (res) {
        const userType = res[0].userType;
        if (userType === "admin") {
          setData([
            {
              id: 1,
              isHeader: true,
              title: "Genel",
            },
            {
              id: 2,
              isHeader: false,
              title: "Home",
              icon: <biIcon.BiHomeCircle className="nav-icon" />,
              pathname: "/",
            },
            {
              id: 3,
              isHeader: false,
              title: "Notification",
              icon: <IoIosNotificationsOutline className="nav-icon" />,
              pathname: "/notification",
            },
            {
              id: 4,
              isHeader: false,
              title: "Ads",
              icon: <TbBox className="nav-icon" />,
              pathname: "/Ads",

            }
          ])

        } if (userType === "station_owner") {
          service.StationService.getStationProfile().then((res) => {

            if (res) {
              var yetki = datas;
              if (res[0].type === "enterprise") {
                yetki.push({
                  id: 12,
                  isHeader: false,
                  title: "customerloyaltyindex",
                  icon: <riIcon.RiPieChartLine className="nav-icon" />,
                  pathname: "/customerloyaltyindex",
                })
                setData(yetki);
                console.log(data)
              }
              setData(yetki);
            }
          });
        } if (userType === "staff") {
          setData([
            {
              id: 1,
              isHeader: true,
              title: "Genel",
            },
            {
              id: 2,
              isHeader: false,
              title: "Home",
              icon: <biIcon.BiHomeCircle className="nav-icon" />,
              pathname: "/",
            },
            // {
            //   id: 6,
            //   isHeader: false,
            //   title: "evaluations",
            //   icon: <bsIcon.BsFileBarGraph className="nav-icon" />,
            //   pathname: "/evaluations",
            // },
            // {
            //   id: 5,
            //   isHeader: false,
            //   title: "campaigns",
            //   icon: <bsIcon.BsArchive className="nav-icon" />,
            //   pathname: "/campaigns",
            // },
            // {
            //   id: 7,
            //   isHeader: false,
            //   title: "memberStation",
            //   icon: <MdLocalGasStation className="nav-icon" />,
            //   pathname: "/memberStation",
            // },
            // {
            //   id: 10,
            //   isHeader: true,
            //   title: "Extras",
            // },
            // {
            //   id: 11,
            //   isHeader: false,
            //   title: "settings",
            //   icon: <riIcon.RiSettings2Line className="nav-icon" />,
            //   pathname: "/settings",
            // },
          ])
        }


      }

    })

  }


  if (loading) {
    <LoadingComp />
  }

  const { sideBtn } = useSelector((state) => state.site);
  return (
    <aside className={sideBtn ? "active" : ""}>
      <nav>
        {data ? data.map((item, index) => {
          return (
            <SidebarItem
              pathname={item.pathname}
              icon={item.icon}
              title={item.title}
              isHeader={item.isHeader}
              key={index}
            />
          );
        }) : null}
      </nav>
    </aside>
  );
};

export default memo(Sidebar);
