import { createSlice } from "@reduxjs/toolkit";
import Service from "../Service";
import { getLocalstorage, removeLocalstorage, setLocalstorage } from "../utils";
import { Decrypt, Encrypt } from "../utils/encrypted";
export const auth = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    userType: null,
  },
  reducers: {
    setlogin: (state, action) => {
      setLocalstorage("user", action.payload);
      state.user = action.payload;
    },

    setlogout: (state) => {
      removeLocalstorage("userType");
      removeLocalstorage("user");
      removeLocalstorage("tkn");
      removeLocalstorage("stationProfile");
      state.user = null;
      state.token = null;
      state.userType = null;
    },

    setToken: (state, action) => {
      setLocalstorage("tkn", action.payload);
      state.token = action.payload;
    },

    setUserType: (state) => {
      const service = new Service();
      service.VisaService.getProfile().then((res) => {
        if (res) {
          const userTypeName = Encrypt(JSON.stringify(res[0]));
          setLocalstorage("userType", userTypeName);
        }
      });
      service.StationService.getStationProfile().then((res) => {
        if (res && res.length > 0) {
          const userTypeName = Encrypt(JSON.stringify(res[0]));
          setLocalstorage("stationProfile", userTypeName);
        }
      });
    },
  },
});

export const { setlogin, setlogout, setToken, setUserType } = auth.actions;

export default auth.reducer;
