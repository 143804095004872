import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetWord } from "../../../../../utils";
import PageMain from "../../../components/PageMain/pagemain";
import FuelCard from "./components/fuelCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";

import { Line, Bar, Pie } from "react-chartjs-2";
import { Progress, Statistic, Table } from "antd";
import moment from "moment";
import { RiBarChartGroupedFill } from "react-icons/ri";
import Service from "../../../../../Service";
import { FaUserAlt } from "react-icons/fa";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
);

const Home = () => {
  const [fullPrice, setFullPrice] = useState();
  const [visitorCard, SetvisitorCard] = useState([]);
  const [followers, setFollowers] = useState(0)
  const [seller, setSeller] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [feedbackData, setFeedbackData] = useState(0);
  const [currencies, setCurrencies] = useState()

  const labels = [
    GetWord("January"),
    GetWord("February"),
    GetWord("March"),
    GetWord("April"),
    GetWord("May"),
    GetWord("June"),
    GetWord("July"),
    GetWord("August"),
    GetWord("September"),
    GetWord("October"),
    GetWord("November"),
    GetWord("December"),
  ];

  useEffect(() => {
    const service = new Service();
    service.StationService.getStationFuelPrices().then((res) => {
      setFullPrice(res);
    });

    service.GeneralService.getcustomers().then((res) => {
      SetvisitorCard(res);
    });

    service.GeneralService.getFavouriteUsers().then((res) => {
      setFollowers(res);
    });

    service.StationService.getStationFeedbacks().then((res) => {
      setFeedbackData(res);
    })
    service.GeneralService.currencies().then((res) => {
      setCurrencies(res);
    })

  }, []);

  useEffect(() => {
    const service = new Service();
    service.StationService.getExpensesForStation(15, pageNumber).then((res) => {
      setSeller(res);
    });
  }, [pageNumber])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          drawBorder: false, // <-- this removes y-axis line
          lineWidth: 0,
        },
      },
      x: {
        display: false,
        grid: {
          drawBorder: false,
          lineWidth: 0, // <-- this removes vertical lines between bars
        },
      },
    },
  };

  const GlobalOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };

  const visitorCardData = {
    labels,
    datasets: [
      {
        label: "Ziyaretçiler",
        data: typeof (visitorCard) === "object" ? Object.keys(visitorCard).map((key) => [
          Number(key),
          visitorCard[key],
        ]) : [],
        borderColor: "#82ca9d",
        backgroundColor: "#82ca9d",
      },
    ],
  };

  const columns = [
    {
      title: GetWord('Date'),
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{moment(text).format('l')}</span>,
    },
    {
      title: GetWord('LicensePlate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
    },
    {
      title: GetWord('fuelName'),
      dataIndex: 'fuelName',
      key: 'fuelName',
      render: (text) => (
        <span className='p-2 btn-primary'>{text}</span>
      )
    },
    {
      title: GetWord('fuelValue'),
      dataIndex: 'fuelValue',
      key: 'fuelValue',
    },
    {
      title: GetWord('payment'),
      key: 'payment',
      render: (_, record) => (
        <span >{record.price} {record.currency}</span>
      )
    },
  ]

  const PieData = {
    labels: [GetWord("FuelQuality"), GetWord("Toilet"), GetWord("Supermarket"), GetWord("Saff")],
    datasets: [
      {
        label: '%',
        data: [feedbackData?.fuel, feedbackData?.toilet, feedbackData?.supermarket, feedbackData?.staff],
        backgroundColor: [
          '#6f5ced',
          '#d7745c',
          '#6eb868',
          '#e9c546',
        ],
        borderColor: [
          '#fff',
          '#fff',
          '#fff',
          '#fff',
        ],

        borderWidth: 5,
      },
    ],
  };

  return (
    <div className="StationDash">
      <PageMain
        title="Merhaba, Hoşgeldiniz"
        descTitle="Petros istasyon paneline hoş geldiniz!"
      >
        {
          currencies ? <div className="d-flex justify-content-end align-items-center">
            <p className="currencies"><span>Altın Çeyrek</span> <br /> <span>{(Number(currencies?.CEYREK.satis)).toFixed(2)}</span>₺</p>
            <p className="currencies"><span>Altın GRAM</span> <br /> <span>{(Number(currencies?.GRAM.satis)).toFixed(2)}</span>₺</p>
            <p className="currencies"><span>EUR</span> <br /> <span>{(Number(currencies?.EUR.satis)).toFixed(2)}</span>₺</p>
            <p className="currencies"><span>USD</span> <br /> <span>{(Number(currencies?.USD.satis)).toFixed(2)}</span>₺</p>

            <p className="currencies"><span>GBP</span> <br /> <span>{(Number(currencies?.GBP.satis)).toFixed(2)}</span>₺</p>
            <p className="currencies"><span>XU100</span> <br /> <span>{(Number(currencies?.XU100.satis)).toFixed(2)}</span>₺</p>
          </div> : null
        }

      </PageMain>
      <div className="row">
        <div className="col-xl-9">
          <div className="row">
            {fullPrice
              ? fullPrice.map((item, index) => {
                return (
                  <div className="col-xl-4 col-lg-6  mb-4" key={index}>
                    <FuelCard item={item} index={index} />
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div className="col-xl-3">
          <div
            className="p-3 mb-4"
            style={{ backgroundColor: "#50bc5e", color: "#fff" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>{GetWord("Followers")}</h4>
                <p>{followers ? followers[0].count : null}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: 15, borderRadius: '100%' }}>
                  <FaUserAlt style={{ width: 21, height: 21 }} />
                </div>
              </div>
            </div>
            <img src={process.env.PUBLIC_URL + "/Assets/images/StationHome.jpg"} className="w-100" alt="" />
          </div>
        </div>
        <div className="col-xl-7 mb-4">
          <div className="visitors-card">
            <div className="visitors-card-header d-flex justify-content-between">
              <div className="">
                <h3 className="visitors-card-header-title">
                  {GetWord("Visitors")}
                </h3>
                <span className="visitors-card-header-desc">
                  {GetWord("VisitorsDesc")}
                </span>
              </div>
              <div className="">sağ</div>
            </div>
            <div className="visitors-body p-3 d-flex justify-content-center">
              <Line options={GlobalOptions} data={visitorCardData} />
            </div>
          </div>
        </div>
        <div className="col-xl-5 mb-4">
          <div className="sellertab-home">
            <h3>{GetWord("sellerTabTitle")}</h3>
            <p className="title">{GetWord("sellerTabDesc")}</p>
            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/Assets/images/Vault-pana.png"}
                alt="Vault-pana"
              />
            </div>
            <p className="text-center min-title">
              {GetWord("sellerTabMinTitle")}
            </p>
            <div className="text-center d-flex justify-content-center ">
              <Link
                className="btn btn-primary text-center w-50"
                to="/sellertab"
              >
                {GetWord("PriceTable")}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="visitors-card">
            <div className="visitors-card-header d-flex justify-content-between">
              <div className="">
                <h3 className="visitors-card-header-title">
                  {GetWord("Sellers")}
                </h3>
                <span className="visitors-card-header-desc">
                  {GetWord("SellersDesc")}
                </span>
              </div>
              <div className=" d-flex justify-content-between">
                <div className="mx-2">
                  <p>{GetWord("Deisel")}</p>
                  <p>{seller?.sumDiesel}</p>
                </div>
                <div className="mx-2">
                  <p>{GetWord("Fuel")}</p>
                  <p>{seller?.sumFuel}</p>
                </div>
                <div className="mx-2">
                  <p>{GetWord("LPG")}</p>
                  <p>{seller?.sumLpg}</p>
                </div>
              </div>
            </div>
            <div className="visitors-body p-3 d-flex justify-content-center">
              <Table columns={columns} scroll={{ y: 'calc(100vh - 4em)' }} pagination={{ position: ["topRight", "none"] }} dataSource={seller?.expenses} />
            </div>
          </div>

        </div>
        <div className="col-xl-5">
          <div className="Evaluation-home">
            <h3>{GetWord("EvaluationTitle")}</h3>
            <p className="title">{GetWord("EvaluationDesc")}</p>
            <div className="row">
              <div className="col-xl-6">
                <Statistic
                  title={GetWord("NowMounth")}
                  value={feedbackData?.thisMonth}
                  precision={2}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<RiBarChartGroupedFill />}
                  suffix="%" />
              </div>
              <div className="col-xl-6">

                <Statistic
                  title={GetWord("LastMounth")}
                  value={feedbackData?.previousMonth}
                  precision={2}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<RiBarChartGroupedFill />}
                  suffix="%" />
              </div>
              <div className="col-xl-5">
                <Pie data={PieData} options={GlobalOptions} />
              </div>
              <div className="col-xl-7">
                <p className="d-flex justify-content-between progress">
                  <span>{GetWord("FuelQuality")}</span><span className='text-end'>({(Number(feedbackData?.fuel)).toFixed(2)}%)</span>
                </p>
                <Progress percent={feedbackData?.fuel} showInfo={false} strokeColor="#6f5ced" />
                <p className="d-flex justify-content-between progress">
                  <span>{GetWord("Toilet")}</span><span className='text-end'>({(Number(feedbackData?.toilet)).toFixed(2)}%)</span>
                </p>
                <Progress percent={feedbackData?.toilet} showInfo={false} strokeColor="#d7745c" />
                <p className="d-flex justify-content-between progress">
                  <span>{GetWord("Supermarket")}</span><span className='text-end'>({(Number(feedbackData?.supermarket)).toFixed(2)}%)</span>
                </p>
                <Progress percent={feedbackData?.supermarket} showInfo={false} strokeColor="#6eb868" />
                <p className="d-flex justify-content-between progress">
                  <span>{GetWord("Saff")}</span><span className='text-end'>({(Number(feedbackData?.staff)).toFixed(2)}%)</span>
                </p>
                <Progress percent={feedbackData?.staff} showInfo={false} strokeColor="#e9c546" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;
