import React, { useState } from 'react'
import { GetWord, getLocalstorage } from '../../../../utils'
import { Decrypt } from '../../../../utils/encrypted'
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import LoadingComp from '../../../shared/loading/loading';
import PageMain from '../../components/PageMain/pagemain';
import SendNotification from './components/sendNotification';
import PastNotification from './components/pastNotification';

const Notification = () => {
    const user = JSON.parse(Decrypt(getLocalstorage("userType")));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(true);
    const [activeTab, setActiveTab] = useState("1");

    if (loading) {
        return <LoadingComp />;
    }

    const onchange = (id) => {
        setActiveTab(id);
    };

    var items = [
        {
            key: "1",
            label: GetWord("PastNotifications"),
            children: <PastNotification userType={user.userType} />,
        }

    ];
    if (user.userType === "admin") {
        items.push({
            key: "2",
            label: GetWord("SendNotification"),
            children: <SendNotification userType={user.userType} />
        })
    }
    return (
        <div className='notification'>
            <PageMain title={GetWord("PastNotifications")} descTitle={GetWord("SendNotification")} />

            <Tabs activeKey={activeTab} onChange={onchange} items={items} />

            {/* {
                user.userType === "admin" ? <div className='notification-admin'>admin</div> : <div className='notification-user'>kullanici</div>
            } */}

        </div>
    )
}

export default Notification