import React from 'react'
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai'
import { GiSandsOfTime } from 'react-icons/gi'
import { GetWord } from '../../../../../../utils'
import { MdLocalGasStation } from 'react-icons/md'

const StaffCard = ({ item }) => {
    return (
        <div className='col-12 col-md-4 col-xl-3'>
            <div className={"staffHome-card p-3 d-flex justify-content-between align-items-center staffHome-card-" + item.status} >
                <div className={"staffHome-card-icon staffHome-card-icon-" + item.status}>
                    {item.status === "totalStation" ? <MdLocalGasStation />
                        : item.status === "waiting" ? <GiSandsOfTime />
                            : item.status === "approved" ? <AiOutlineCheckSquare />
                                : item.status === "rejected" ?
                                    <AiOutlineCloseSquare />
                                    : null}
                </div>
                <div className="staffHome-card-content">
                    <p className="staffHome-card-content-title">{item.statusCount + " " + GetWord("station")}</p>
                    <p className="staffHome-card-content-smallTitle">{GetWord("staff-" + item.status)}</p>
                </div>
            </div>
        </div>
    )
}

export default StaffCard