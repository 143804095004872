import baseService from "./baseService";

export default class VisaService extends baseService {
  constructor() {
    super();
    this.schemaName = "VisaService";
  }

  getProfile() {
    return this.getObject("api", "getProfile");
  }
}
