import React, { useCallback, useEffect, useState } from 'react'
import Service from '../../../../../Service';
import LoadingComp from '../../../../shared/loading/loading';
import { RiChargingPileFill } from 'react-icons/ri';
import { MdLocalGasStation } from 'react-icons/md';
import { InputNumber } from 'antd';
import { GetWord } from '../../../../../utils';
import { toast } from 'react-toastify';
import FuelupdatePriceCard from './fuelupdatePriceCard';

const FuelupdatePrice = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(true);
    const [isUpdate, SetIsUpdate] = useState(true);


    useEffect(() => {
        const service = new Service();
        service.StationService.getStationFuelPrices().then((res) => {
            setData(res);
            setLoading(false);
        });
    }, [isUpdate]);

    if (loading) {
        return <LoadingComp />;
    }


    return (
        <div className='settings-price row'>
            {data ? data.map((item, index) => {

                return (<FuelupdatePriceCard item={item} key={index} index={index} SetIsUpdate={SetIsUpdate} setLoading={setLoading} />)
            }) : null}

        </div>
    )
}

export default FuelupdatePrice;