import React, { useEffect, useState } from "react";
import Service from "../../../../Service";
import { GetWord } from "../../../../utils";
import { MdOutlineCheck } from "react-icons/md";

const Sellertab = () => {
  const [Package, setPackage] = useState();

  const getPackage = () => {
    const service = new Service();
    service.GeneralService.packages().then((res) => {
      setPackage(res);
    });
  };

  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div className="sellertab">
      <div className="text-center">
        <h2 className="sellertab-title">{GetWord("selltabTitle")}</h2>
        <p className="sellertab-desc">{GetWord("selltabDesc")}</p>
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        {Package
          ? Package.prices.map((item, index) => {
              return (
                <div
                  className={"sellertab-card pack-" + (index + 1)}
                  key={index}
                >
                  {index === 1 ? (
                    <div className="ribbon">{GetWord("Advantageous")}</div>
                  ) : null}
                  <h3 className="sellertab-card-title">{item.text}</h3>
                  <h2 className="sellertab-card-price">
                    <span className="sellertab-card-price-currency">
                      {item.currency}
                    </span>
                    {item.price}
                    <span className="sellertab-card-price-mount">
                      /{GetWord("Month")}
                    </span>
                  </h2>
                  {Package.info.map((value, i) => {
                    if (value[item.text] === 1) {
                      return (
                        <div className="sellertab-card-item" key={value.id}>
                          <MdOutlineCheck />
                          {GetWord(value.text)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Sellertab;
